@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  background-color: #f4f7fd;
}

a {
  text-decoration: none;
}
